import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import { Form, Checkbox, Select, FormGroup, Button, Icon, Divider, Dropdown, Confirm, Label, Popup, Segment } from "semantic-ui-react";

import * as QUESTIONAIRE_ACTIONS from '../../../../actions/questionaireActions';
import * as QUESTION_ACTIONS from "../../../../actions/questionActions";
import * as CONDITION_ACTIONS from "../../../../actions/conditionActions";
import * as LOOKUPS_ACTIONS from "../../../../actions/lookupsActions";
import * as LOOKUPSQNAIRE_ACTIONS from "../../../../actions/lookupsQnaireActions";
import * as INTENTS_ACTIONS from "../../../../actions/intentsActions";
import * as TEMPLATES_ACTIONS from "../../../../actions/questionWhatsappTemplateActions";

import ConditionComponent from "./ConditionComponent";
import { toast } from "react-toastify";

export function QuestionDetails(props) {
  const [state, setState] = useState({
    questionaire: { ...props.questionaire },
    question: { ...props.question },
    section: { ...props.section },
    showCondition: props.question != null && props.question.isConditional,
    condition: !_.isEmpty(props.condition) ? { ...props.condition } : {},
    redirectToggleDisabled: false,
    showConfirmIsActive: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const questionTypes = [
    { key: 1, text: "Text", value: "val_string", isCompatible: true },
    { key: 2, text: "Paragraph", value: "val_paragraph", isCompatible: true },
    { key: 3, text: "Number", value: "val_num", isCompatible: true },
    { key: 4, text: "Date - DD-MM-YYYY", value: "date_long", isCompatible: true },
    { key: 5, text: "Year - YYYY", value: "date_year", isCompatible: true },
    { key: 6, text: "Multiple Choice Select One", value: "mc_sel_one", isCompatible: true },
    { key: 7, text: "Multiple Select", value: "multi_select", isCompatible: true },
    { key: 8, text: "Yes/No", value: "yes_no", isCompatible: true },
    // { key: 9, text: "Scale", value: "scale", isCompatible: true },
    { key: 10, text: "Image Upload", value: "upload_image", isCompatible: true },
    { key: 11, text: "Document Upload", value: "upload_document", isCompatible: true },
    { key: 12, text: "Video Upload", value: "upload_video", isCompatible: true },
    { key: 13, text: "Location", value: "location", isCompatible: true },
    // { key: 14, text: "Bullet List", value: "bullet_list", isCompatible: false },
    // { key: 15, text: "Number List", value: "number_list", isCompatible: false },
    { key: 16, text: "No Answer", value: "no_answer", isCompatible: true },
    { key: 17, text: "Map", value: "map", isCompatible: false },
    { key: 18, text: "Map Yes/No", value: "map_yes_no", isCompatible: false },
    { key: 19, text: "Link to Questionnaire", value: "link_to_qnaire", isCompatible: true },
    { key: 20, text: "Image ID", value: "image_no_answer", isCompatible: true },
    { key: 21, text: "WhatsApp Template", value: "whatsapp_template", isCompatible: true },
  ];

  questionTypes.sort((a, b) => a.text.localeCompare(b.text));

  let intentsList = [];

  let intentNone = {
    key: 1,
    value: '',
    text: ' None',
  };
  intentsList.push(intentNone);

  props.intents.forEach((intent, index) => {
    intent = {
      key: intent.key,
      value: intent.name,
      text: intent.name,
    };
    intentsList.push(intent);
  });

  intentsList.sort((a, b) => a.text.localeCompare(b.text));

  useEffect(() => {
    props.fetchLookupQnaireListAction(props.question.questionaireId, props.question.tenantId);
    props.fetchLookupListAction(props.question.questionaireId, props.question.tenantId);
    props.fetchCondition(props.question.id, props.question.tenantId);
    props.fetcQuestions(props.questionaire.id, props.section.id, props.questionaire.tenantId);
    props.fetchIntentListAction();
    props.fetchWhatsappTemplatesAction();
  }, []);

  useEffect(
    (prevProps, prevState) => {
      if (_.isEmpty(state.condition) && !_.isEmpty(props.condition)) {
        setState((state) => ({
          ...state,
          condition: props.condition,
          showCondition: !_.isEmpty(props.condition),
        }));
      }
    },
    [props.condition]
  );

  const confirmDeactivate = () => {
    let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";

    return (
      <Confirm
        header='Warning:'
        content={contentMessage}
        open={state.showConfirmIsActive}
        onCancel={() => setState((state) => ({ ...state, showConfirmIsActive: false }))}
        onConfirm={() => {
          let { question } = state;
          question.questionaireId = state.questionaire.id;

          props.deactiveQuestionaireAction(props.questionaire);
          props.commitQuestion(
            state.question,
            state.condition,
            state.mapLayer,
            state.questionaire.tenantId,
            props.isNew,
            state.questionaire.name
          );
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      />
    );
  }


  const onSubmit = (data) => {
    const isQuestionnaireActive = props.questionaire.status === "active" || props.questionaire.status === "underReview";

    if (isQuestionnaireActive) {
      setState((state) => ({ ...state, showConfirmIsActive: true }));
    } else {
      // Check if required fields in ConditionComponent are filled
      const { condition } = state;
      const isSectionSelected = !!condition.targetSectionId;
      const isOperatorSelected = !!condition.conditionOperator;
      const isValueProvided = !!condition.value;

      if (state.question.isConditional === true && (!isSectionSelected || !isOperatorSelected || !isValueProvided)) {
        toast.error("Please complete all required fields in the condition section.");
        return;
      }

      const newSequence = Number(state.question.sequence);
      const isDuplicate = newSequence !== "" && props.questions.some(question => question.id !== state.question.id && question.sectionId === state.question.sectionId && question.sequence === newSequence);

      if (isDuplicate) {
        toast.error("The entered sequence number is already in use.");
      } else {
        let { question } = state;
        question.questionaireId = state.questionaire.id;

        props.commitQuestion(
          state.question,
          state.condition,
          state.mapLayer,
          state.questionaire.tenantId,
          props.isNew,
          state.questionaire.name
        );

        if (_.isEmpty(props.onComplete)) {
          props.onComplete();
        }
      }
    }
  };


  const showCondition = () => {
    setState((state) => ({
      ...state,
      question: {
        ...state.question,
        isConditional: !state.showCondition ? true : false,
      },
      showCondition: !state.showCondition,
    }));
  };

  const handleConditionEdit = (condition) => {
    setState((state) => ({ ...state, condition: condition }));
  };

  const questionTypeDropdown = () => {
    let defaultValue = state.question.questionType
      ? state.question.questionType
      : "";

    return (
      <Controller
        name="question_type"
        control={control}
        rules={{ required: true }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.invalid ? "invalid-input-field" : ""}
                {...field}
                placeholder="Select a question type"
                fluid
                selection
                search
                options={questionTypes}
                value={defaultValue}
                onChange={(e, { value }) => {
                  const selectedQuestionType = questionTypes.find((type) => type.value === value);
                  const isCompatible = selectedQuestionType ? selectedQuestionType.isCompatible : false;

                  setState((state) => ({
                    ...state,
                    question: {
                      ...state.question,
                      questionType: value,
                      whatsAppCompatible: isCompatible,
                    },
                  }));
                  field.onChange(value);
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a question type</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  const isActive = () => {

    return (
      <Checkbox
        toggle
        label="Is Active"
        checked={!!state.question.isActive}
        onChange={() => {
          setState((prevState) => ({
            ...prevState,
            question: {
              ...prevState.question,
              isActive: !prevState.question.isActive,
            },
          }));
        }}
      />
    );
  };

  const personalInfo = () => {

    return (
      <Popup
        trigger={
          <Checkbox
            toggle
            label="Personal Information"
            checked={!!state.question.isPersonalInfo}
            onChange={() => {
              setState((prevState) => ({
                ...prevState,
                question: {
                  ...prevState.question,
                  isPersonalInfo: !prevState.question.isPersonalInfo,
                },
              }));
            }}
          />
        }
        content="When checked the answers of this question will be hidden."
        mouseEnterDelay={500}
        mouseLeaveDelay={500}
        position="right center"
      />
    );
  };

  const mobileQuestion = () => {

    return (
      <Popup
        trigger={
          <Checkbox
            toggle
            label="Display On WhatsApp Only"
            checked={!!state.question.whatsAppOnly}
            onChange={() => {
              setState((prevState) => ({
                ...prevState,
                question: {
                  ...prevState.question,
                  whatsAppOnly: !prevState.question.whatsAppOnly,
                },
              }));
            }}
          />
        }
        content="When checked this question will only be displayed on WhatsApp. Usefull for questions that guide users on WhatsApp."
        mouseEnterDelay={500}
        mouseLeaveDelay={500}
        position="right center"
      />
    );
  };


  const canRepeatFilter = () => {
    return (
      <>
        <Form.Field>
          <Checkbox
            toggle
            label="Can Repeat"
            checked={!!state.question.canRepeat}
            onChange={() => {
              setState((prevState) => ({
                ...prevState,
                question: {
                  ...prevState.question,
                  canRepeat: !prevState.question.canRepeat,
                },
              }));
            }}
          />
        </Form.Field>
        {state.question.canRepeat && (
          <>
            <Form.Field>
              <label>Question Sequence to repeat from:</label>
              <input
                type="number"
                placeholder="Question Sequence to repeat from"
                min="1"
                value={state.question.extraInfo}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    question: { ...prevState.question, extraInfo: e.target.value },
                  }));
                }}
              />
            </Form.Field>
            <Divider />
          </>
        )}
      </>
    );
  };

  const canRedirectFilter = () => {

    return (
      <>
        <Form.Field>
          <Checkbox
            toggle
            label="Redirect to question"
            checked={!!state.question.canRedirect}
            disabled={state.redirectToggleDisabled}
            onChange={() => {
              setState((prevState) => ({
                ...prevState,
                question: {
                  ...prevState.question,
                  canRedirect: !prevState.question.canRedirect,
                },
              }));
            }}
          />
        </Form.Field>
        {state.question.canRedirect && (
          <>
            <Form.Field>
              <label>Select Section:</label>
              {/* Dropdown to select the section */}
              <Dropdown
                placeholder="Select Section"
                options={props.sections
                  .filter((section) => section.questionaireId === state.questionaire.id && section.id !== state.section.id) // Filter by questionnaire ID and exclude current section
                  .map((section) => ({
                    key: section.id,
                    value: section.id,
                    text: section.name,
                  }))}
                value={state.question.redirectSectionId}
                onChange={(e, { value }) => {
                  setState((prevState) => ({
                    ...prevState,
                    question: { ...prevState.question, redirectSectionId: value },
                  }));
                }}
                selection
              />
            </Form.Field>
            {state.question.redirectSectionId && (
              <>
                <Form.Field>
                  <label>Select Question:</label>
                  {/* Dropdown to select the question */}
                  <Dropdown
                    placeholder="Select Question"
                    options={props.questions
                      .filter(
                        (question) =>
                          question.sectionId === state.question.redirectSectionId &&
                          question.sectionId !== state.section.id // Filter questions based on selected section and exclude current section
                      )
                      .map((question) => ({
                        key: question.id,
                        value: question.id,
                        text: question.question,
                      }))}
                    value={state.question.redirectQuestionId}
                    onChange={(e, { value }) => {
                      // Update the selected question in state
                      setState((prevState) => ({
                        ...prevState,
                        question: {
                          ...prevState.question,
                          redirectQuestionId: value,
                        },
                      }));
                    }}
                    selection
                  />
                </Form.Field>
                <Divider />
              </>
            )}
          </>
        )}
      </>
    );
  };

  const conditionFilter = () => {

    return (
      <>
        <Form.Field>
          <Checkbox
            toggle
            label="Question has condition?"
            checked={state.showCondition}
            onChange={() => {
              showCondition();
            }}
          />
        </Form.Field>
        {state.showCondition && (
          <ConditionComponent
            sectionId={state.section.id}
            questionId={state.question.id}
            questionaire={props.questionaire}
            isNew={props.isNew}
            onConditionEdit={handleConditionEdit}
          />
        )}
      </>
    );
  };

  const intentDropdown = () => {

    let defaultValue = state.question.intentName
      ? state.question.intentName
      : "";

    return (
      <Controller
        name="question_intent"
        control={control}
        rules={{ required: false }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.invalid ? "invalid-input-field" : ""}
                {...field}
                placeholder="Select intent"
                fluid
                selection
                search
                options={intentsList}
                value={defaultValue}
                onChange={(e, { value }) => {
                  setState((state) => ({
                    ...state,
                    question: {
                      ...state.question,
                      intentName: value,
                    },
                  }));
                  field.onChange(value);
                }}
              />
            </div>
          );
        }}
      />
    );
  };


  const watchQuestionType = watch("question_type", state.question.questionType);

  useEffect(() => {
    if (watchQuestionType === "link_to_qnaire") {
      setState((state) => ({
        ...state,
        redirectToggleDisabled: true,
        question: {
          ...state.question,
          canRedirect: false,
          redirectSectionId: "",
          redirectQuestionId: "",
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        redirectToggleDisabled: false,
      }));
    }
  }, [watchQuestionType]);


  const questionaireId = state.questionaire.id;
  const canRepeat =
    state.question.canRepeat === 0 ? false : state.question.canRepeat;

  const questionaireLookups = props.lookups
    .filter((lookup) => lookup.questionaireId === questionaireId)
    .map((lookup) => ({
      key: lookup.id,
      text: lookup.name,
      value: lookup.id,
    }));

  const lookupTypes = questionaireLookups;

  const questionaireLookupsQnaire = props.lookupsQnaires
    .filter((lookup) => lookup.questionaireId === questionaireId)
    .map((lookup) => ({
      key: lookup.id,
      text: lookup.name,
      value: lookup.id,
    }));

  const lookupQnaireTypes = questionaireLookupsQnaire;

  const templateOptions = [
    { key: 'none', text: 'None', value: {} },
    ...props.templates.map((template) => ({
      key: template.id,
      text: template.templateName,
      value: template,
    })),
  ];
  templateOptions.sort((a, b) => {
    if (a.text === 'None') {
      return -1;
    } else if (b.text === 'None') {
      return 1;
    } else {
      return a.text.localeCompare(b.text);
    }
  });

  const _buildFilterGroup = () => {
    return (
      <>
        <Form.Group widths="equal">
          <Form.Field>
            {isActive()}
          </Form.Field>
          <Form.Field>
            {personalInfo()}
          </Form.Field>
          <Form.Field>
            {mobileQuestion()}
          </Form.Field>
        </Form.Group>
        <Divider />
        <Form.Group widths="equal">
          <Form.Field>
            {canRepeatFilter()}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            {canRedirectFilter()}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            {conditionFilter()}
          </Form.Field>
        </Form.Group>
      </>
    )
  }

  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
      {confirmDeactivate()}
      <Form.Field>
        {["image_no_answer"].includes(state.question.questionType) ? (
          <>
            <label>Image ID:</label>
          </>
        ) : (
          <label>Question Text</label>
        )}
        <input
          name="question_text"
          className={errors.question_text ? "invalid-input-field" : ""}
          {...register("question_text", { required: true, maxLength: 500 })}
          placeholder={
            ["image_no_answer"].includes(state.question.questionType)
              ? "Image ID"
              : "Question Text"
          }
          value={state.question.question}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              question: { ...prevState.question, question: e.target.value, whatsAppTemplate: {} },
            }));
          }}
        />
        {errors.question_text && errors.question_text.type === "required" && (
          <p className="validation-error">* Please enter a name</p>
        )}
        {errors.question_text && errors.question_text.type === "pattern" && (
          <p className="validation-error">
            * May only contain alphabetical characters
          </p>
        )}
        {errors.question_text && errors.question_text.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 500 characters long
          </p>
        )}
      </Form.Field>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Powerpoint Reference Prefix:</label>
          <input
            name="Powerpoint_Reference_Prefix"
            className={errors.questionaire_name ? "invalid-input-field" : ""}
            {...register("Powerpoint_Reference_Prefix", { pattern: /^[^_]*$/, })}
            placeholder="Powerpoint Reference Prefix"
            value={state.question.pptRefPrefix}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\s/g, "");
              if (inputValue.length <= 12) {
                setState((prevState) => ({
                  ...prevState,
                  question: { ...prevState.question, pptRefPrefix: inputValue },
                }));
              }
            }}
          />
          {errors.Powerpoint_Reference_Prefix &&
            errors.Powerpoint_Reference_Prefix.type === "pattern" && (
              <p className="validation-error">
                * May not contain "_" or spaces.
              </p>
            )}
        </Form.Field>
        <Form.Field>
          <label>Powerpoint Reference:</label>
          <input
            className="visible-input"
            name="Powerpoint_Reference"
            placeholder="Powerpoint Reference"
            value={state.question.pptRef}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Sequence:</label>
          <input
            {...register("sequence", { required: false })}
            placeholder="1"
            type="number"
            min="1"
            value={state.question.sequence}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                question: {
                  ...prevState.question,
                  sequence: e.target.value.replace(/\D/, ""),
                },
              }));
            }}
          />
        </Form.Field>
        <Form.Field>
          <label >Intent Name</label>
          {intentDropdown()}
        </Form.Field>
      </Form.Group>
      <FormGroup widths="equal">
        <Form.Field>
          <label>
            Question Type
            <div className="question-type-label" style={{ display: 'inline-block', marginLeft: '10px' }}>
              {questionTypes.find((type) => type.value === state.question.questionType)?.isCompatible === true ? (
                <Label color="green" style={{ fontWeight: 'bold' }}>WhatsApp Compatible</Label>
              ) : (
                <Label color="red" style={{ fontWeight: 'bold' }}>NOT WhatsApp Compatible</Label>
              )}
            </div>
          </label>
          {questionTypeDropdown()}
        </Form.Field>
        {["whatsapp_template"].includes(state.question.questionType) && (
          <Form.Field>
            <label style={{ marginTop: '8px' }}>Select WhatsApp Template</label>
            <Select
              placeholder="<Select template>"
              options={templateOptions}
              value={state.question.whatsAppTemplate}
              onChange={(e, { value, options }) =>
                setState((prevState) => ({
                  ...prevState,
                  question: {
                    ...prevState.question,
                    whatsAppTemplate: value,
                    question: options.find((option) => option.value === value)?.text || "",
                  },
                }))
              }
            />
          </Form.Field>
        )}
        {["mc_sel_one", "multi_select"].includes(state.question.questionType) && (
          <Form.Field>
            <label style={{ marginTop: '8px' }}>Select Lookup</label>
            <Select
              placeholder="<Select Lookup>"
              options={lookupTypes}
              value={state.question.lookupId}
              onChange={(e, { value }) =>
                setState((prevState) => ({
                  ...prevState,
                  question: { ...prevState.question, lookupId: value },
                }))
              }
            />
          </Form.Field>
        )}
        {["map", "map_yes_no"].includes(state.question.questionType) && (
          <Form.Field>
            <label htmlFor="hiddenFileInput">Upload a Map Layer File</label>
            <input
              className={errors.mapLayer ? "invalid-input-field" : ""}
              {...register("mapLayer", { required: true })}
              id="hiddenFileInput"
              name="mapLayer"
              type="file"
              accept=".kml"
              style={{ display: "none" }}
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  mapLayer: e.target.files[0],
                }))
              }
            />
            <button
              type="button"
              onClick={() => {
                document.getElementById("hiddenFileInput").click();
              }}
            >
              Choose File
            </button>
            <label>
              {state.mapLayer?.name || props.question.kmlName || "No file selected"}
            </label>
            {errors.mapLayer && errors.mapLayer.type === "required" && (
              <p className="validation-error">* Please select a .kml file</p>
            )}
          </Form.Field>
        )}
        {["link_to_qnaire"].includes(state.question.questionType) && (
          <Form.Field>
            <label>Select Questionnaire Lookup</label>
            <Select
              placeholder="<Select Questionnaire Lookup>"
              options={lookupQnaireTypes}
              value={state.question.lookupId}
              onChange={(e, { value }) => {
                setState((prevState) => ({
                  ...prevState,
                  question: { ...prevState.question, lookupId: value },
                }));
              }}
            />
          </Form.Field>
        )}
      </FormGroup>


      <Segment>
        {_buildFilterGroup()}
      </Segment>

      <Divider />
      <Button.Group floated="right">
        <Button
          className="danger"
          onClick={() => {
            if (_.isEmpty(props.onComplete)) {
              props.onComplete();
            }
          }}
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button type="submit" className="success">
          <Icon name="checkmark" /> Ok
        </Button>
      </Button.Group>
    </Form>
  );
}

QuestionDetails.defaultProps = {
  question: {},
  sectionId: null,
};

const mapStateToProps = (state, ownProps) => {
  let question = {};
  let isNew = false;
  let newQuestion = {
    question: "",
    extraInfo: "",
    printString: "",
    isMandatory: false,
    isActive: true,
    canRepeat: false,
    canRedirect: false,
    isPersonalInfo: false,
    whatsAppOnly: false,
    sequence: 1,
    questionType: "",
    whatsAppCompatible: false,
    isUserCreated: true,
    isConditional: false,
    questionaireName: ownProps.questionaire.name,
    questionaireId: ownProps.questionaire.id,
    qnairePptRef: ownProps.questionaire.pptRef,
    sectionId: ownProps.question.sectionId,
    pptRef: "",
    anonymityLevel: "",
    pptRefPrefix: "",
    redirectQuestionId: "",
    redirectSectionId: "",
    lookupId: "",
    kmlName: "",
    intentName: "",
    whatsAppTemplate: {},
    tenantId: ownProps.questionaire.tenantId,
  };

  if (!ownProps.question.hasOwnProperty("id")) {
    question = newQuestion;
    isNew = true;
  } else {
    question = _.isEmpty(state.questions)
      ? newQuestion
      : Object.values(
        state.questions.filter(
          (question) =>
            question.id === ownProps.question.id &&
            question.status !== "isDeleted"
        )
      )[0];
  }

  return {
    question: question,
    questions: state.questions,
    section: _.isEmpty(state.sections) ? {} : Object.values(state.sections.filter((section) => section.id === question.sectionId))[0],
    // section: state.sections,
    lookups: _.isEmpty(state.lookups) ? [] : state.lookups,
    lookupsQnaires: _.isEmpty(state.lookupsQnaires) ? [] : state.lookupsQnaires,
    isNew: isNew,
    intents: _.isEmpty(state.intents) ? [] : _.sortBy(state.intents, 'intentName'),
    tenantId: state.tenantManagement.activeTenant.tenantId,
    anonymityLevels: state.anonymityLevels,
    templates: state.questionWhatsAppTemplates,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    commitQuestion: (question, condition, mapLayer, tenantId, isNewItem, questionaireName) => dispatch(QUESTION_ACTIONS.questionSaveAction(question, condition, mapLayer, tenantId, isNewItem, questionaireName)),
    deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
    fetchCondition: (questionId, tenantId) => dispatch(CONDITION_ACTIONS.fetchConditionsAction(questionId, tenantId, false)),
    fetchLookupListAction: (questionaireId, tenantId) => dispatch(LOOKUPS_ACTIONS.fetchLookupsAction(questionaireId, tenantId)),
    fetchLookupQnaireListAction: (questionaireId, tenantId) => dispatch(LOOKUPSQNAIRE_ACTIONS.fetchLookupsQnaireAction(questionaireId, tenantId)),
    fetcQuestions: (questionaireId, sectionId, tenantId) => dispatch(QUESTION_ACTIONS.fetchQuestionsAction(questionaireId, sectionId, tenantId)),
    fetchIntentListAction: () => dispatch(INTENTS_ACTIONS.fetchIntentsAction()),
    fetchWhatsappTemplatesAction: () => dispatch(TEMPLATES_ACTIONS.fetchWhatsappTemplatesAction()),
  })
)(QuestionDetails);